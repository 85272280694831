import dayjs from 'dayjs';
import { configure } from 'mobx';
import relativeTime from 'dayjs/plugin/relativeTime';
import { env } from 'env';
import { stores } from 'state/stores';
import { initAmplitude, sendEvent } from './events';
import { log } from './log';
import { fetchUserContentAfterLaunch } from './fetchUserContent';
import { setupThemeCookie } from './themeCookie';
import { logout } from 'utils/logout';
import { setSentryUserData } from './sentry';
import { initPaddle } from './paddle';
import { initAPInterceptors } from './APInterceptors';
import { captureException } from '@sentry/nextjs';
import { initAmplitudeUserPropertiesListener } from './amplitude/userPropertiesListener';

dayjs.extend(relativeTime);

configure({
  enforceActions: 'never',
});

export const bootstrap = async () => {
  log('🥾 bootstrapping');
  log(`🔗 api: `, env.apiUrl);

  //
  // There are excessive try/catch/captureExceptions below. Right now some percentage
  // of users aren't able to bootstrap. I'm trying to capture every possible error case
  // to figure out the underlying issue
  //

  try {
    initPaddle();
  } catch (error) {
    captureException(`[bootstrap] - Error initializing Paddle: ${error}`);
  }
  try {
    initAmplitude();
    initAmplitudeUserPropertiesListener();
  } catch (error) {
    captureException(`[bootstrap] - Error initializing Amplitude: ${error}`);
  }
  try {
    setSentryUserData();
  } catch (error) {
    captureException(`[bootstrap] - Error setting Sentry user data: ${error}`);
  }
  try {
    setupThemeCookie();
  } catch (error) {
    captureException(`[bootstrap] - Error setting theme cookie: ${error}`);
  }

  try {
    const path = window.location.pathname.split('/')[1];
    sendEvent('appLaunch', { authenticated: stores.auth.isAuthenticated, path });
  } catch (error) {
    captureException(`[bootstrap] - Error sending app launch event: ${error}`);
  }

  stores.paddlePrices.fetch().catch(e => {
    captureException(`[bootstrap] - Error fetching Paddle prices: ${e}`);
  });

  initAPInterceptors();

  if (!stores.auth.isAuthenticated) {
    stores.lifecycle.setBootstrapComplete();
    return;
  }

  try {
    stores.account.hydrate();
    stores.userPreferences.hydrate();
    stores.routines.hydrate();
    stores.routineFolders.hydrate();
    stores.exerciseTemplates.hydrate();
    stores.exerciseTemplateUnits.hydrate();
    stores.followingStatus.hydrate();
    stores.followCounts.hydrate();
    stores.betaFeatures.hydrate();
    stores.serverStorage.hydrate();
  } catch (error) {
    captureException(`[bootstrap] - Error hydrating stores: ${error}`);
    logout();
    return;
  }

  await stores.subscription.fetch().catch(error => {
    if (error.response?.status === 401) {
      logout();
    }
  });

  stores.lifecycle.setBootstrapComplete();

  await fetchUserContentAfterLaunch();
};
