import dayjs from 'dayjs';
import { generateCueGroup } from 'hevy-shared';
import { autorun, IReactionDisposer } from 'mobx';
import { stores } from 'state/stores';
import { themeState } from 'styleguide/theme';
import { updateAmplitudeUserProperties } from 'utils/events';

let autorunDisposer: IReactionDisposer | undefined = undefined;

export const initAmplitudeUserPropertiesListener = () => {
  if (!!autorunDisposer) autorunDisposer();

  autorunDisposer = autorun(() => {
    if (!stores.account.id) return;

    const userProperties: Record<string, any> = {
      username: stores.account.username,
      userId: stores.account.id,
      privateProfile: stores.account.private_profile,
      workoutCount: stores.workouts.workouts.length,
      isPro: stores.subscription.isPro,
      routineCount: stores.routines.routines.length,
      weightUnitPreference: stores.userPreferences.weightUnit,
      distanceUnitPreference: stores.userPreferences.distanceUnit,
      theme: themeState.current.name,
      createdAt: stores.account.created_at,
      cueGroup: generateCueGroup(stores.account.id),
      isHevyCoachClient: stores.account.is_coached,
      isHevyCoachCoach: stores.account.is_a_coach,
      sex: stores.account.sex,
      birthYear: stores.account.birthday ? dayjs(stores.account.birthday).year() : null,
      isGympassPlanActive: stores.account.is_gympass_plan_active,
      isStravaConnected: stores.account.is_strava_connected,
    };

    updateAmplitudeUserProperties(userProperties);
  });
};
